import React from 'react';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

const SignatureGenerator = () => {
  return (
    <Layout headerTheme="color">
      <SEO title="Signature Generator" noIndex={true} />
      <section className="panel">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1>Email signature generator</h1>
        <p>
          Fill in the details below. You will be taken to a new page that
          displays your formatted email signature.
        </p>
        <p>
          Select this, and then copy and paste it into the signature field in
          Google Mail settings.
        </p>
        <form
          action="https://kavanagh.dev/functional/wreel.agency/signature.php"
          method="post"
          target="_blank"
          className="form"
        >
          <div className="form__field">
            <label htmlFor="name" className="form__label">
              Your name:
            </label>
            <input
              type="text"
              className="form__input"
              name="name"
              id="name"
              required
            />
          </div>

          <div className="form__field">
            <label htmlFor="title" className="form__label">
              Your title:
            </label>
            <input
              type="text"
              className="form__input"
              name="title"
              id="title"
              required
            />
          </div>

          <div className="form__field">
            <input type="submit" className="form__submit" value="Generate" />
          </div>
        </form>
      </section>
    </Layout>
  );
};

export default SignatureGenerator;
